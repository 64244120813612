/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

exports.onClientEntry = () => {
  window.addEventListener('load', () => {
    document.body.className = document.body.className.replace(/\bno-js\b/, '');

    // If user presses tab key, enable focus outlines
    document.body.addEventListener('keyup', function(e) {
      if (e.which === 9) /* tab */ {
        document.body.classList.remove('no-outline');
      }
    });
  });
}
