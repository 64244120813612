module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Destination Selsey","short_name":"Selsey","start_url":"/","background_color":"#1d3054","theme_color":"#ffffff","display":"standalone","icon":"src/images/ds_logo_crop.svg"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[],"precachePages":["/*","/accommodation/*","/accommodation/*/*","/adventure-and-activities/*","/adventure-and-activities/*/*","/eat-drink/*","/eat-drink/*/*","/explore/*","/explore/*/*","/heritage/*","/heritage/*/*","/insider/*","/insider/*/*","/local-business/*","/local-business/*/*","/whats-on/*","/whats-on/*/*","/wildlife/*","/wildlife/*/*","/*/step/*","/*/step/*/*"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-C60Z7K5CSG"],"gtagConfig":{"anonymize_ip":true},"pluginConfig":{"respectDNT":true}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
