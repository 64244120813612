// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-local-business-js": () => import("./../src/pages/local-business.js" /* webpackChunkName: "component---src-pages-local-business-js" */),
  "component---src-templates-activity-detail-js": () => import("./../src/templates/activity-detail.js" /* webpackChunkName: "component---src-templates-activity-detail-js" */),
  "component---src-templates-article-js": () => import("./../src/templates/article.js" /* webpackChunkName: "component---src-templates-article-js" */),
  "component---src-templates-business-category-js": () => import("./../src/templates/business-category.js" /* webpackChunkName: "component---src-templates-business-category-js" */),
  "component---src-templates-business-js": () => import("./../src/templates/business.js" /* webpackChunkName: "component---src-templates-business-js" */),
  "component---src-templates-event-details-js": () => import("./../src/templates/event-details.js" /* webpackChunkName: "component---src-templates-event-details-js" */),
  "component---src-templates-index-js": () => import("./../src/templates/index.js" /* webpackChunkName: "component---src-templates-index-js" */),
  "component---src-templates-journey-end-js": () => import("./../src/templates/journey-end.js" /* webpackChunkName: "component---src-templates-journey-end-js" */),
  "component---src-templates-journey-hashtag-js": () => import("./../src/templates/journey-hashtag.js" /* webpackChunkName: "component---src-templates-journey-hashtag-js" */),
  "component---src-templates-journey-intro-js": () => import("./../src/templates/journey-intro.js" /* webpackChunkName: "component---src-templates-journey-intro-js" */),
  "component---src-templates-journey-map-js": () => import("./../src/templates/journey-map.js" /* webpackChunkName: "component---src-templates-journey-map-js" */),
  "component---src-templates-journey-secret-js": () => import("./../src/templates/journey-secret.js" /* webpackChunkName: "component---src-templates-journey-secret-js" */),
  "component---src-templates-journey-step-js": () => import("./../src/templates/journey-step.js" /* webpackChunkName: "component---src-templates-journey-step-js" */),
  "component---src-templates-point-of-interest-js": () => import("./../src/templates/point-of-interest.js" /* webpackChunkName: "component---src-templates-point-of-interest-js" */),
  "component---src-templates-whats-on-page-js": () => import("./../src/templates/whats-on-page.js" /* webpackChunkName: "component---src-templates-whats-on-page-js" */),
  "component---src-templates-whats-on-upcoming-page-js": () => import("./../src/templates/whats-on-upcoming-page.js" /* webpackChunkName: "component---src-templates-whats-on-upcoming-page-js" */)
}

